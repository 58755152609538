<template>
  <div class="activist-rating-content content">
    <div class="content-search">
      <ui-input search :placeholder="$t('search')" :read-only="isAuth" v-model="search" @input="searchList" @focus="visibleAlertInFocus" />
      <div class="content-search__icons">
        <img @click="visibleAlertInMenu" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="" @click="visibleAlertInSort">
          </template>
          <template #body>
            <div class="sort">
                <p
                    @click="changeSort(item)"
                    v-for="(item, key) in sortData"
                    :class="['sort__item', { active: item.value === sortBy?.value }]"
                    :key="key"
                >
                  {{ item.text }}
                  <img v-if="item.value === sortBy.value" src="@/assets/svg/red-check.svg" alt="">
                </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <search-filter
        v-if="visibleFilter"
        @submit="updateList"
        @reset="resetSearch"
        :disabled="isPointsValid || isNumbersValid"
    >
      <div class="form__row-select">
        <ui-select
            v-model="searchObj.region"
            :label="$t('region')"
            :options="getRegions"
        />
        <ui-select
            v-model="searchObj.starRating"
            :label="$t('status')"
            :options="statusData"
        />
      </div>
      <div class="form__row-input">
        <ui-input
            v-model="searchObj.scoreFrom"
            :label="$t('number-of-points-from')"
            :error="isPointsValid"
            v-mask="'########'"
        />
        <ui-input
            v-model="searchObj.scoreTo"
            :label="$t('number-of-points-to')"
            :error="isPointsValid"
            v-mask="'########'"
        />
        <ui-input
            v-model="searchObj.ordersFrom"
            :label="$t('number-of-requests-from')"
            :error="isNumbersValid"
            v-mask="'########'"
        />
        <ui-input
            v-model="searchObj.ordersTo"
            :label="$t('number-of-requests-to')"
            :error="isNumbersValid"
            v-mask="'########'"
        />
      </div>
    </search-filter>
    <div class="activist-content-body content-body">
      <ui-loader v-model="visibleLoader" />
      <div v-if="!visibleLoader" class="">
        <p v-if="ecoActivists.length" class="content-body__title">
          {{ $t('eco-activists-open-length', {from: ecoActivists.length, to: total }) }}
        </p>
        <div v-if="ecoActivists.length" class="content-body__container" >
          <activist-rating-card v-for="(item, index) in ecoActivists" :data="item" :key="index" @visibleAlert="visibleAlertInFocus"/>
        </div>
        <p v-else class="empty">{{ $t('no-users') }}</p>
        <ui-button v-if="visibleLoadMore && total !== ecoActivists.length" @click="getActivistList" class="content-body__btn" color="white">
          {{ $t('show-more') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {getCookie} from "@/utils/cookie";

const defaultSearch = {
  starRating: { name: 'Все', value: 'all' },
  scoreFrom: null,
  scoreTo: null,
  ordersFrom: null,
  ordersTo: null,
  region: null,
}

export default {
  name: "ActivistRatingContent",
  components: {
    UiLoader: () => import("@/components/ui/UiLoader"),
    UiButton: () => import("@/components/ui/UiButton"),
    UiInput: () => import("@/components/ui/UiInput"),
    UiSelect: () => import("@/components/ui/UiSelect"),
    UiMenu: () => import("@/components/ui/UiMenu"),
    SearchFilter: () => import("@/components/common/SearchFilter"),
    ActivistRatingCard: () => import("@/components/template/activist-rating/ActivistRatingCard")
  },

  data() {
    return {
      visibleLoader: true,
      visibleLoadMore: false,
      total: 0,
      visibleFilter: false,
      visibleSort: false,
      statusData: [
        { name: this.$t('all'), value: 'all' },
        { name: this.$t('beginner'), value: 0 },
        { name: this.$t('advanced'), value: 1 },
        { name: this.$t('experienced'), value: 2 },
        { name: this.$t('specialist'), value: 3 },
        { name: this.$t('expert'), value: 4 },
        { name: this.$t('professional'), value: 5 },
      ],
      search: '',
      searchObj: JSON.parse(JSON.stringify(defaultSearch)),
      sortBy: { text: this.$t('number-of-points'), value: 'star' },
      sortData: [
        { text: this.$t('by-status'), value: 'score' },
        { text: this.$t('by-number-of-points'), value: 'star' },
        { text: this.$t('by-nickname'), value: 'login' },
        { text: this.$t('by-region'), value: 'region' },
      ]
    }
  },

  computed: {
    ...mapGetters(['ecoActivists', 'getRegions', 'getUser']),

    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },

    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },

    isPointsValid() {
      return (Number(this.searchObj.scoreFrom) >= Number(this.searchObj.scoreTo)) && !!this.searchObj.scoreFrom && !!this.searchObj.scoreTo
    },

    isNumbersValid() {
      return (Number(this.searchObj.ordersFrom) >= Number(this.searchObj.ordersTo)) && !!this.searchObj.ordersFrom && !!this.searchObj.ordersTo
    },

    isAuth () {
      return !getCookie('authToken') || this.$route.query.type === 'unconfirmed'
    }
  },

  methods: {
    ...mapMutations(['setEcoActivists']),
    ...mapActions(['users', 'reqRegions']),

    resetSearch() {
      this.searchObj = JSON.parse(JSON.stringify(defaultSearch))
      this.updateList(true)
    },

    changeSort(val) {
      this.visibleSort = false
      this.sortBy = val
      this.updateList()
    },

    getActivistList() {
      this.users({
        roles: ['Active'],
        starRating: this.searchObj.starRating.value === 'all' ? null : this.searchObj.starRating.value,
        scoreFrom: this.searchObj.scoreFrom,
        scoreTo: this.searchObj.scoreTo,
        ordersFrom: this.searchObj.ordersFrom,
        ordersTo: this.searchObj.ordersTo,
        search:  this.search,
        sort: this.sortBy?.value,
        take: 21,
        skip: this.ecoActivists.length,
        order: this.sortBy?.value === 'region' || this.sortBy?.value === 'login' ? 'ASC' : null
      }).then((res) => {
        this.total = res.meta.total
        this.visibleLoader = false
        this.setEcoActivists(this.$store.state.usersStore.ecoActivists.concat(res.data))
        if (res.data.length === 21) {
          this.visibleLoadMore = true
          return
        }

        this.visibleLoadMore = false
      })
    },

    searchList() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.updateList()
      }, 500)
    },

    updateList(reset = false) {
      this.visibleLoader = true
      this.visibleFilter = reset
      this.users({
        roles: ['Active'],
        starRating: this.searchObj.starRating.value === 'all' ? null : this.searchObj.starRating.value,
        scoreFrom: this.searchObj.scoreFrom,
        scoreTo: this.searchObj.scoreTo,
        ordersFrom: this.searchObj.ordersFrom,
        ordersTo: this.searchObj.ordersTo,
        regionId: this.searchObj.region?.id,
        search:  this.search,
        sort: this.sortBy?.value,
        take: 21,
        order: this.sortBy?.value === 'region' || this.sortBy?.value === 'login' ? 'ASC' : null
      }).then((res) => {
        this.visibleLoader = false
        this.total = res.meta.total
        this.setEcoActivists(res.data)
        if (res.data.length === 21) {
          this.visibleLoadMore = true
          return
        }

        this.visibleLoadMore = false
      })
    },

    visibleAlertInFocus () {
      this.isAuth ? this.$emit('visibleAlert') : false
    },

    visibleAlertInMenu () {
      this.isAuth ? this.$emit('visibleAlert') : this.visibleFilter = !this.visibleFilter
    },

    visibleAlertInSort () {
      if (this.isAuth) {
        this.$emit('visibleAlert');
        setTimeout(() => {
          this.visibleSort = false;
        }, 50)
      }
    }
  },

  mounted() {
    this.reqRegions()
    this.getActivistList()
    this.searchObj.starRating.name = this.$t('all')
  },

  destroyed() {
    this.setEcoActivists([])
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-body {

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
    margin-bottom: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__btn {
    margin-top: 50px;
  }
}

.content-search {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #FFFFFF;

  @media (max-width: 992px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
    }
  }
}
::v-deep .filter__row {
  display: flex !important;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 992px) {
    display: flex !important;
    flex-direction: column;
  }
}
.form__row-input {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.form__row-select{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
